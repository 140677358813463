@import '../../mixins.scss';

.card{
    &-user{
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid $borderColor !important;
        border-left: 1px solid $borderColor !important;
        border-right: 1px solid $borderColor !important;
        box-shadow: $boxShadowCard;
        padding: 20px;
    }
}

.div-card-ponto{
    padding: 10px;
    background-color: $colorWhite;
}

.card-clock{
    color: $colorWhite;
    width: 80%;
    margin: auto;
    & .card-clock-header, .card-clock-footer{
        font-weight: 400;
        background-color: $colorContent2 !important;
        
    }
    
    & .card-clock-header{
        text-align: center;
        margin-bottom: 0;
    }
    & .card-clock-body{
        padding: 2rem 0px;
        box-shadow: $boxShadow;
        & span{
            color: $colorWhite;
        }
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: $colorContent1 !important;
        & .hours-minutes{
            font-size:4rem;
            line-height: 55PX;
        }
        
    }
    & .card-clock-footer{
        & span{
            color: $colorWhite;
        }
        display: flex;
        justify-content: space-between;
    }
}

.row-calendario{
    width: 1050px;
    margin: auto;
    padding: 0 !important;
    display: flex;
    .button-calendar{
        display: flex;
        border-radius: 0 !important;
        border: 0.1px solid  #ccc9c9 !important;
        width: 40px;
        &-config{
            @extend .button-calendar;
            position: relative;
            .configs{
                position: absolute;
                z-index: 10;
                width: 150px;
                height: auto;
                right: 0;
                top: 28px;
                border: 0.1px solid rgb(130, 124, 124);
                background-color: #6C7379;
                box-shadow: 0 0 16px #0000007a;
                display: flex;
                flex-direction: column;
                font-size: 0.9rem;
                text-align: start;
                div{
                    font-size: 0.8rem;
                    color: $colorWhite;
                    padding: 5px 7px;
                    transition: 0.25s;
                    &:hover{
                        background-color: #ccc9c9;
                        color: $colorBlack;
                        font-weight: 900
                    }

                }
            }
        }
    }
}
.card-calendario{
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1100px) {
        display: block;
    }
}
.calendario{
    display: grid;
    grid-template-columns: 150px 150px 150px 150px 150px 150px 150px;
    column-gap: 0;
    gap: 0;
    justify-content: center;
    text-align: center;
    
    border: 0.1px solid #ccc9c9;
    
    .dias-semana{
        font-size: 0.8rem;
        display: inline-block;
        padding: 5px 10px;
        border: 0.1px solid #ccc9c9;
        background-color: #f0f0f078;
    }
    .dias{
        border-collapse: collapse;
        border-spacing: 0;
        text-align: end;
        padding: 5px;
        border: 0.1px solid #ccc9c9;
        border-left: none;
        border-bottom: none;
        height: 120px;
        // font-size: 1.1rem;
        display: inline-block;
        cursor: pointer;
        background-color: $colorWhite;
        transition: 0.25s ease-in-out;
        .card-local{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            overflow: hidden;


            .total-horas-trabalhadas{
                font-size: 1.5rem;
            }
            .dia-atual{
                position: absolute;
                right: 10px;
                top: 5px;
                font-size: 1.1rem;
            }

        }

        &:hover{
            transform: scale(1.1);
            border: 0.1px solid #ccc9c9;
            box-shadow: $boxShadow
        }
        &-anteriores{
            @extend .dias;
            cursor: default;
            font-size: 0.8rem;
            &:hover{
                transform: scale(1.0);
            }
            color: #a5a2a2;
        }
    }
}