.card-dashoard{
    overflow: hidden;
    &-image-dashboard{
        transition: 0.5s;
        &:hover{
            transform: scale(1.1,1.1);
        }
    }
    .card-footer{
        font-size: 0.9rem;
        p{
            margin: 5px 0;
        }
        i{
            margin-right: 2px;
            font-size: inherit;
        }
    }
}    