.div-row-editable {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;

	&-input {
		width: 50% !important;
	}
}

.modal-footer {
	&-no-border {
		border: none !important;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap !important;
		& button {
			max-width: 250px;
		}
	}
}

.div-itens-sing-danfe {
	display: flex;
	position: fixed;
	background: #30354f;
	width: 200px;
	justify-content: space-around;
	color: white !important;
	align-items: center;
	i {
		display: block;
		color: white !important;
		cursor: pointer;
	}
}

.flters {
	display: flex;

	&-option {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.bi-select-img {
	&:hover {
		cursor: pointer;
		color: blue;
	}
}

.lineScanner {
	position: absolute;
	// top: 0;
	left: 0.8rem;
	width: 100%;
	height: 8px;
	background: rgba(255, 0, 0, 0.488);
	z-index: 2;
    animation: lineMove 5s linear infinite alternate;
    
}

@keyframes lineMove {
    0%   {top:0px;}
    50%  {top:285px;}
    100% {top:0px;}
}