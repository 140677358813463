@import '../../../mixins.scss';

.monitoramento-pedido{
    color: #07395f !important;

    transition: 0.5s;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
        box-shadow: 0 0 50px #63014561;
    }
    &-list{
        margin: 0;
        transition: 0.5s;
        cursor: pointer;
        &:hover{
            transform: translateY(-5px);
            box-shadow: 0 0 50px #63014561;
            border: 1px solid #630146c4 !important;
            background-color: #630146c8 !important;

            div, span, p, i {
                color: white !important;
            }
        }
    }

    .header{
        background-color: #2871a7;
        height: 150px;
        color: $colorWhite;
        padding: 10px 20px;
        font-weight: 500;
        text-align: center;
        &-status{
            font-size: 1.1rem;
            background: #63014561;
            box-shadow: 0 4px 30px #63014561;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(5px);
            border: 0.5px solid #63014561;
            height: fit-content;
        }

    }
    .body{
        border: 0.1px solid #2871a7;
        margin: auto;
        margin-top: -85px;
        margin-bottom: 20px;
        padding: 20px 10px;
        width: 90%;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 0px 30px rgb(34 94 126 / 28%);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 0.9rem !important;

        .text{
            &-title{
                font-size: 0.7rem;
                font-weight: 800;
                position: relative;
                padding-left: 5px;
                &::before{
                    border: 0.1px solid #2871a7;
                    left: 0;
                    position: absolute;
                    content: "";
                    height: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        // font-size: 0.8rem;
    }
}
.bi-select-option{
    transition: 0.5s;
    i{
        padding: 7px;
        cursor: pointer;
    }
    .selected{
        color: $colorWhite;
        background-color: #012b5e;
        box-shadow: 0 0 10px #307FE2;
        border-radius: 25%;
    }
}


.card-filters-monit{
    display: flex;
    color: $colorWhite;
    margin-bottom: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    
    .card-new-filter{
        display: flex;
        position: relative;
        background-color: #307FE2;
        border-radius: 20px;
        color: $colorWhite;
        padding: 5px;
        align-items: center;
        min-width: 20%;
        max-width: 500px;
        height: fit-content;
    
        .bi{
            background-color: #307FE2;
            text-align: center;
            box-shadow: 0 0 10px #307FE2;
            cursor: pointer;
            position: absolute;
            border-radius: 50%;
            width: 45px;
            font-size: 2rem;
            left: 0;
        
        }
        .campo-buscar{
            border: transparent;
            background-color: white;
            border-radius: 20px;
            width: 100%;
            padding: 2px 0px 2px 50px;
            text-transform: uppercase;
            &:focus{
                outline: none;
            }
        }
    }
    .option{
        margin: 10px 4px;
        background-color: #307FE2;
        padding: 5px 15px;
        border-radius: 10px;
        color: inherit;
        font-family: cursive;
        font-weight: 700;
        cursor: pointer;
        transition: 0.5s;
        &:hover{
            background-color: #012b5e;
            box-shadow: 0 0 10px #307FE2;
        }
        &.selected{
            background-color: #012b5e;
            box-shadow: 0 0 10px #307FE2;
        }
    }

}

.carregando-logistica{
    color: rgb(255, 255, 255);
    background-color: rgb(0, 219, 72);
    padding: 4px 6px;
    border-radius: 4px;
    text-align: center;
    box-shadow: rgba(10, 6, 30, 0.5) 0px 0px 5px;
    border: 1px solid rgba(226, 227, 223, 0.5);
    width: max-content;
    opacity: 1;
    animation: animationLoading 2s ease-in-out infinite;
    &.show{
        display: inherit;
    }
    &.hidden{
        display: none;
    }

}

@keyframes animationLoading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}