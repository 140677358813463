@import '../../mixins.scss';

.card{
    &-default{
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid $borderColor !important;
        border-left: 1px solid $borderColor !important;
        border-right: 1px solid $borderColor !important;
        box-shadow: $boxShadowCard;
        padding: 20px;
    }
}


