@import '../../mixins.scss';

.button-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    &-span{
        
        color: $colorWhite;
        margin: 0 5px;
    }
}

.black{
    color: $colorBlack !important;
}