@import '../../../mixins.scss';

.filter-icon{
    cursor: pointer;
    position: relative;
    transition: 0.5s;
    &:hover{
        color: wheat;
    }
    &:active{
        color: wheat;
    }

    &::after{
        position: absolute;
        background-color: white;
        height: 100px;
        width: 100px;
        border: 1px solid black;
    }
}
.card-filters-options{
    position: absolute !important;
    max-height: 150px !important;
    min-width: 100px !important;
    // background-color: red;
    color: black;
    top: 15px;
    padding: 0px;
 
    display: flex;
    flex-direction: column;

    overflow: hidden;
    overflow-y: auto;

    padding: 2px 5px;
    span{
        transition: 0.5s;
        font-size: 0.8rem;
        padding: 0.45rem 0.35rem;

        &:hover{
            background-color: #79797970;
        }
    }
    &.right{
        left: 0px !important;
    }
    &.left{
        right: 0px !important;
    }
}