@import '../../mixins.scss';

.card{
    box-shadow: $boxShadowCard;
    border-color: transparent !important;
    margin-bottom: 1.5rem;
    &-dashboard{
        position: relative;
        padding: 20px;
        padding-right: 60px;
        & p{
            color: $colorLink;
            margin: 0;
        }
        &:hover{
            transition: 0.5s;
            & .bi-dashboard{
                width: 80px;
                height: 100%;
                font-size: 30px;
                border-radius: 0;
                line-height: 2rem;
            }
        }
    }
    &-color-1{
        & i {
            background-color: $colorContent1;
        }
    }
    &-color-2{
        & i {
            background-color: $colorContent2;
        }
    }
    &-color-3{
        & i {
            background-color: $colorContent3;
        }
    }
    &-color-4{
        & i {
            background-color: $colorContent4;
        }
    }
    &-color-5{
        & i {
            background-color: $colorContent5;
        }
    }
    
}

.bi-dashboard{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    font-size: 25px;
    transition: 0.5s;
    color: $colorWhite;
    background-color: #7266ba;
    border-radius: 30px 0 30px 30px;
}

.data-padrao{
    font-weight: 500;
}

.filter-data{
    position: relative;
    margin-left: auto;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    border: solid 0.5px;
    transition: 0.3s;
    background: linear-gradient(to left, transparent 50%, #2f9e96 50%) right;
    background-size: 200% 100%;
    transition: 0.5s;
    z-index: 1;
    backdrop-filter: 1;
    // overflow: hidden;
    .filho{
        position: absolute;
        right: 0;
        transition: 0.5s;
        z-index: -20;
        opacity: 0;
        border: 0.2px solid #2f9e96;
        padding: 5px;
        border-radius: 5px;
        top: -1px;

    }
    
    &:hover{
        color: #fff;
        background-position: left;
        border-color: #2f9e96 ;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.397);
        .filho{
            right: 110px;
            color: black;
            // z-index: 0;
            opacity: 1;

        }
    }
}
.dashboard-filters{
    margin-left: 0.75rem;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    border: solid 0.5px;
    transition: 0.3s;
    background: linear-gradient(to left, transparent 50%, #2f9e96 50%) right;
    background-size: 200% 100%;
    transition: 0.5s;
    z-index: 1;
    backdrop-filter: 1;

    &:hover{
        color: #fff;
        background-position: left;
        border-color: #2f9e96 ;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.397);
       
    }
}

.date-lead-graph{
    .popover{
        display: none;
        position: absolute;
        background-color: #10103b;
        color: #fff;
        width: 180px;
        padding: 10px 15px;
        bottom: 15px;
        left: 15px;
        font-weight: 700;
    }
    &:hover{
        .popover{
            z-index: 9;
            display: flex;
        }
    }
}