@import '../../../mixins.scss';

$colorBlue: #8d00ff;


.div-status {
    /* padding: 17px; */
    display: flex;
    justify-content: center;
    text-align: center;




    &-ativo {
        padding: 2px 16px;
        font-size: 0.9rem;
        width: 100%;
        border-radius: 10px;
        background: $colorGreen;
        color: $colorWhite;
    }

    &-inativo {

        padding: 2px 16px;
        font-size: 0.9rem;
        width: 100%;
        border-radius: 10px;
        background: $colorRed;
        color: $colorWhite;
    }
    &-manutencao {

        padding: 2px 16px;
        font-size: 0.9rem;
        width: 100%;
        border-radius: 10px;
        background: $colorYellow;
        color: $colorWhite;
    }
}

.admin {
    display: flex;
    justify-content: left;
    padding-left: 10px;
    padding-right: 10px;

    
    & .excluir, .editar, .verificar, .imprimir {
        cursor: pointer;
        &:hover{
            i{
                color:$colorBlue;
            }
        }

    }
}

.bg-selected{
    &-danger{
        background-color: $colorRed;
        & td{
            color: $colorWhite !important;
    
        }
    }
    background-color: $colorLink !important;
    & td{
        color: $colorWhite !important;

    }
}

.rowDataWidth{
    min-width: 10rem;
}

.rowDanger{
    background-color: red;
    td{
        color: white !important;
    }
}

.bg{
    &-cancelado{
        background-color: rgba(255, 0, 0, 0.815);
        td{
            color: white !important;
            i{
                color: white!important;
            }
        }
    }
    &-conferido{
        background-color: #e2f104;
        td{
            color: black !important;
            i{
                color: black!important;
            }
        }
    }
    &-planejado{
        background-color: rgba(0, 148, 0, 0.815);
        td{
            color: white !important;
            i{
                color: white!important;
            }
        }
    }
}