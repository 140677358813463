@import '../../mixins.scss';
.card-financeiro{
    border: 1px solid #e6e9f1 !important;
    border-radius: 12px !important;
    padding: 24px 18px 28px;
    font-size: 14px;

    .carga-nome{
        font-weight: 700;
        color: #1f2d54;
        font-size: 14px;
        margin-bottom: 8px;
    }
    .valor-carga{
        font-weight: 700;
        color: #1f2d54;
        font-size: 24px;
        margin-bottom: 8px;
    }
    .status-carga{
        margin-bottom: 12px;
    }
    .passos-realizados{
        display: flex;
        justify-content: flex-start;
        align-items:center ;
        i{
            margin-right: 6px;
        }
    }
    .btn-card-financeiro{
        background-image: linear-gradient(#3d7fff, #4b5dff);
        border: 1px solid #3961d9;
        transition: 0.5s;
        &:hover{
            transform: scale(1.03);
            box-shadow: 0 2px 16px 1px rgba(74, 58, 255, 0.467) !important;
        }
    }



    i{
        font-size: 1.3rem;
        color: $colorLink;
    }
}