@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'mixins.scss';

html,
body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: $colorBlack;
    font-size: 14px;
    background-color: $colorBackground1;
    // background-image: linear-gradient(45deg, #2193b0, #6dd5ed);
}

i {
    font-size: 1.2rem;
}

.nav-link-e {
    border-bottom: 1px solid #ebebee;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    &-profile {
        padding: 4px 10px;
    }
    &-link-direto {
        cursor: pointer;
        &:hover{
            a{
                color: $colorAuxiliary1 !important;
            }
        }
    }


    & span {
        color: $colorBlack;

        /* cor da fonte */
        & i {
            color: $colorBlack;
            /* cor do icone */
            margin-right: 5px;
        }
    }

    &:hover {
        background-color: $colorBackground1;

        & span {
            color: $colorAuxiliary1;

            /* cor da fonte */
            & i {
                color: $colorAuxiliary1;
                /* cor do icone */
            }
        }

        &::before {
            content: "";
            position: absolute;
            right: -18px;
            background: $colorAuxiliary1;
            height: 15px;
            width: 40px;
            -webkit-transform: rotate(90deg);
            transform: rotate(-45deg);
            bottom: 0;
        }
    }

    &:is(.active) {

        & span {
            color: $colorAuxiliary1;

            /* cor da fonte */
            & i {
                color: $colorAuxiliary1;
                /* cor do icone */
            }
        }

        &.nav-link-e-profile {
            background-color: $colorBackground1;

            & i {
                color: $colorAuxiliary1;
            }

            & .sidebar-option-title-profile {
                color: $colorAuxiliary1;
            }
        }
    }

    &:is(.active)::before {
        content: "";
        position: absolute;
        right: -18px;
        background: #2962ff;
        height: 15px;
        width: 40px;
        -webkit-transform: rotate(90deg);
        transform: rotate(-45deg);
        bottom: 0;
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: $colorAuxiliary1Light;
}

.error-form {
    font-size: 1.1rem;
    color: $colorRed !important;
    padding-left: 3px;
}

hr {
    opacity: 0.1;
}

h1 {
    font-size: 18px;
    color: $colorFontHeader;
    font-weight: 500;
}

button {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-radius: 20px !important;
    font-size: 1.1rem !important;
    box-shadow: $boxShadow !important;
    word-wrap: normal;
    
}
.button-report{
    @extend .btn;
    border-radius: 9px !important;
}

// input{
//     text-transform: uppercase;
// }



.table {
    & thead {
        background-color: $colorBackground1;
    }

    & th {
        white-space: nowrap;
    }

    & th,
    td {
        // white-space: nowrap;
        font-size: 12px;
        padding: 0.75rem;
    }

    & td {
        color: $colorLink;
    }

    .fixed-th{
        position: sticky !important;
        left: 0;
        outline: 1px solid #dee2e6 !important;
        background-color: #2f917d;
        color: #fff;   
        z-index: 10;
    }
}

image {
    width: 100%;
}

.responsive-table{
    height: 100vh;
    max-height: 400px;
    overflow: auto;
    position: relative; 
    border: 0.1px solid rgba(0, 0, 0, 0.184);
    box-shadow: 0 0 7px #0000002f;
    & table{
        position: relative;
        height: 100px;
        background-color: #ffffff;
        --bs-table-bg: '';
        & thead{
            position: sticky;
            top: 0;
        }
    }
}

.section-filters-table{
    margin: 10px 0 25px ;
    & .form-control-input{
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0 !important;
    }
    
}

.card-header{
    background-color: initial;
    padding: 0;
    border-bottom: 1px solid $borderColor;
    position: relative;
    margin-bottom: 20px;
    &::before{
        content: "";
        background-color: $colorAlert;
        height: 1px;
        width: 4rem;
        position: absolute;
        left: 0;
        bottom: -1px;
    }
}

@media print { 
    .no-printme  { display: none;}
    .printme  { display: block;}
}

//loading circle
.box{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    .percentual{
        position: relative;
        height: 100px;
        width: 100px;
        svg{
            position: relative;
            height: 100px;
            width: 100px;
            circle{
                fill: none;
                stroke-width: 7;
                stroke: #054f5e;
                transform: translate(5px, 5px);
                stroke-dasharray: 250;
                stroke-dashoffset: 250;
                &:nth-child(1){
                    stroke-dashoffset: 0;
                    stroke: #0dcaf0;
                }
                &:nth-child(2){
                    stroke-dashoffset: 50;
                    stroke: #054f5e;
                }
            }
        }
        .numero{
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #054f5e;
            h2{
                font-size: 1.5rem;
                margin: 0;
                span{
                    font-size: 1rem;

                }
            }

        }
    }
}

.nav-tabs{
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;

    .nav-item{
        white-space: nowrap;
    }

    &::-webkit-scrollbar{
        display: none;
    }
}

.bi-info{
    position: relative;
    border-radius: 50%;
    background-color: #8fa0bc;
    color: $colorWhite;
    &.describe::after{
        content: attr(data-descricao);
        position: absolute;
        background-color: #8fa0bc;
        box-shadow: 0 0 10px rgb(0 0 0 / 32%);;
        font-size: 0.8rem;
        padding: 10px;
        border-radius: 10px;
        width: 150px;
        border: 0.1px solid #00000044;
    }
}

@media (min-width: 1400px) {
    .container{
      max-width: 1700px !important;
    } 
  }