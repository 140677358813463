@import '../../mixins.scss';



.sidebar{
    position: fixed;
    display: flex;
    flex-wrap: nowrap !important;
    background-color: $colorWhite;
    color: $colorLink;
    height:100%;
    width: 220px;
    top:60px;
    left: 0;
    z-index: 50;
    box-shadow: $boxShadow;
    transition: width 1s;



    &-nav{
        width: 100%; 
        margin-bottom: 60px;
        overflow: hidden auto;

    }

    &-option{
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &-profile{
            padding: 0 8px;
        }
        &-title{
            color: $colorLink;
        }
        &-title-profile{
            color: $colorLink;
            font-size: 0.9rem;
            padding: 0 2px;
        }
        &-icon-profile{
            i{
                color: $colorLink;
                font-size: 0.9rem;
            }
        }
    }
    
    // @media screen and (max-width: 1000px) {
    //     // width: 0;
    // }
}

span{
    font-size: 1.1rem;
}

.nav-link-e-logout{
    border-bottom:1px solid #ebebee;    
}


.transition0{
    width: 0px !important;
    transition: width 500ms !important;
}
.transition100{
    width: 220px!important;
    transition: width 500ms !important;
}