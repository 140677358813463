@import '../../mixins.scss';

.card{
    &-user{
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid $borderColor !important;
        border-left: 1px solid $borderColor !important;
        border-right: 1px solid $borderColor !important;
        box-shadow: $boxShadowCard;
        padding: 20px;
    }
}

.responsive-table{
    max-height: 400px;
    overflow: auto;
}

span{
    color: $colorBlack;
    white-space: nowrap;
    word-wrap: unset;
}
.filters-card-map{
    margin-top: 0 !important;
}

.wrapper-button-icon{
    position: relative;
    width: 100%;
    overflow: hidden;
    .icon{
        position: absolute !important;
        height: 100%;
        padding: 4px 5px;
        left: 0px;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
    }
    .input{
        padding-left: 30px !important;
    }
}

.pin{
    padding: 5px;
    border-radius: 3px;
    &-selected{
        background-color: grey;
    }
}

.cep{
    white-space: nowrap;
}
.estado{
    white-space: nowrap;
}