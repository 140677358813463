@import '../../mixins.scss';
.map-div{
    position: relative;
    height: 100vh;
    width: auto;
    box-shadow: $boxShadow;
    .map-div-routes{
        position: absolute;
        z-index: 1;
        min-width: 50%;
        padding: 10px;
        overflow: hidden;
        width: 50%;


        
        .map-div-routes-filter{
            @media screen and (max-width: 500px){
                width: 90%;
            }
            background-color: white;
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: $boxShadow;

            .waypoints{
                display: flex;
                overflow: auto;
            }
            .centerized-button-map{
                cursor: pointer;
                background-color: $colorLink;
                text-align: center;
                width: 30px;
                border-radius: 15px;
            }
        }
        .map-div-monitoramentos{
            transition: 1s;
            width: 700px;
            min-width: 250px;
            // max-width: 850px;
            min-height: 50vh;
            height: max-content;
            max-height: 75vh;
            overflow: auto;
            &.hide-card{
                width: 15vw;
                min-height: 45px;
                max-height: 45px;
                overflow: hidden;
            }
            .card-header{
                display: flex;
                justify-content: space-between;
            }

            .card-truck{
                border: 0.1px solid #0000ff3f;
                box-shadow: 0 0 20px #0000ff6f;
                border-radius: 10px;
                padding:20px;
                display: grid;
                color: #020272c4;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                transition: 0.7s;
                height: 300px;
                &.show-card-truck-infos{
                    height: 600px;
                }
                .card-info{
                    display: flex;
                    align-items: center;
                    .div-card-icons{
                        position: relative;
                        :nth-child(1){
                            position: relative;
                            opacity: 0.4;
                            filter: blur(1px);
                        }
                        :nth-child(2){
                            position: absolute;
                            left: 25px;
                            bottom: 5px;
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }
                    i{
                        font-size: 2.5rem ;
                        margin-right: 10px;
                    }
                    .card-title{
                        display: flex;
                        flex-direction: column;
                        .title{
                            color: #0066ffbd;
                            font-size: 0.7rem;
                        }
                        .msg{
                            color: #0000ffb3;
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }
                }

                .truck_img{
                    position: absolute;
                    right: 5px;
                    top: 10px;
                    background-image: url('../../assets/truck_blue.png');
                    right: left;
                    top: -14px;
                    height: 150px;
                    width: 115px;
                    background-size: contain;
                    right: -24px;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    mix-blend-mode: multiply;
                }

                &:hover{
                    transform:  translateY(-1.1rem);
                }
            }
            background-color: white;
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: $boxShadow;

            @media screen and (max-width: 600px) {
                width: 200px !important;
                .hide-card{
                    width: 100% !important;

                }
            }
        }
    }
}

.map-marker{
    position: absolute;
    top: -25px;
    left: -50px;
    background-color: $colorLink;
    padding: 20px;
    border-radius: 10px;

}
.map-marker-truck{
    background-image: url('https://i.pinimg.com/564x/e0/8b/14/e08b1415885d4d2ddd7fd3f75967da29.jpg');
    position: absolute;
    top: -25px;
    left: -50px;
    background-color: $colorLink;
    padding: 20px;
    border-radius: 10px;

}