.filters {
	display: flex;
	flex-direction: column;

	&-option {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: 0.75rem;
		border-bottom: 1px solid #008037;
		transition: 0.5s;
		&-headers {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			&-title {
				font-size: 1.3rem;
				font-weight: 600;
			}
		}

		&-options {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-left: 0.75rem;
			margin-top: 0.5rem;
            
            // transitions
			max-height: 0;
			transition: max-height 0.3s ease-out;
			overflow: hidden;
			&.show {
				max-height: 360px;
			}
		}
	}
}

.form-check-selecao-carga{
	.form-check-input{
		width: 2em !important;
		height: 2em !important;
	}
}
