@import '../../mixins.scss';

.card-transferencia{
    border: 1px solid #00000031;
    text-align: center;
    background-color: #439f2c;
    padding: 0.55rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    color: white;
    box-shadow: 0 0 10px #00000075;
    .transferencia{
        
        border: 1px solid black;
        text-align: center;
        background-color: rgb(251, 251, 255);
        color: black;
        padding-block: 0.75rem;
        padding-left: 0.55rem;
        width: fit-content;
        transition: 0.5s;
        cursor: pointer;
        &:hover{
            transform: translateY(-20px);
            background-color: rgba(1, 30, 52, 0.559);
            box-shadow: 0 0 15px #00000083;
            color: white;
            border-color: rgba(255, 255, 255, 0.445);
        }
    }
}