@import '../../mixins.scss';

.card-table{
    position: 'relative', height;
    border: 1px solid $colorBlack;
}
.max-heigth-card-table{
    max-height: 500px !important;
    &-filters{
        @extend .max-heigth-card-table;
        overflow: auto;
    }
}
.card-filter {
    // height: 50px !important;
    overflow: hidden;
    padding-bottom: 0.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    transition: 0.5s;
    border: 1px solid #00000046 !important;

    & .card-body-filter {
      padding: 1rem;

      // max-height: 250px;
      & .card-title-filter {
        margin-top: -3px;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & .span-card-filter{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        & i {
            transition: 0.5s;
          color: black !important;

          &:hover {
            cursor: pointer;
            transition: 0.5s;
            max-height: max-content;
          }

        }
      }

      & .card-text {
        // @extend .scrollbar-relatorio;
        margin: 0.2rem 0;
        padding: 0rem 1rem;
        height: 230px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: block;
          width: 0.5rem;
        }

        & .form-check {
          display: flex;
        }
      }
    }
}