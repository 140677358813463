.div-caminhoes{
    position: relative;
    height: 690px;
    // display: flex;
    justify-content: center;
    width: 100%;
    // border: 1px solid red;
    // overflow: auto;

    .eixo{
        z-index: 0;
        border: 6px solid black;
        position: absolute;
        background-color: black;
        &-central{
            @extend .eixo;
            height: 500px;
            left: 200px;
            top:50px;
            width: 1%;
        }
        &-frontal{
            @extend .eixo;
            height: 10px;
            top:20px;
            width: 310px;
            left: 50px;
            top: 75px
        }
        &-traseiro{
            @extend .eixo;
            height: 10px;
            top:20px;
            width: 310px;
            left: 50px;
            &-1{
                top: 475px

            }
            &-2{
                top: 353px

            }
        }
    }
    .frente{
        
        .esquerda{
            
            img{
                z-index: 1;
                width: 33px;
                cursor: pointer;
                rotate: 180deg;
                position: absolute;
                &:hover{
                    & + div{
                        display: block;
                    }
                }
            }
            div{
                z-index: 103;
                &.numero-pneu{
                    z-index: 2;
                    top:120px;
                    display: block;
                }
                background-color: rgb(255, 0, 64);
                color: black;
                font-weight: 400;
                font-size: 0.9rem;
                position: absolute;
                padding: 3px 10px;
                display: none;
            }
            &-1{
                img{
                    left:40px;
                    top:40px;
                }
                div{

                    left:35px;
                    top:150px;
                }
            }
            &-2{
                img{
                    left:70px !important;
                    top:40px ;
                }
                div{

                    left:79px;
                    top:150px;
                }
            }
            &-3{
                img{
                    rotate: 0deg;
                    left:310px !important;
                    top:40px ;
                }
                div{
                    left:310px !important;
                    top:150px;
                }
            }
            &-4{
                img{
                    rotate: 0deg;
                    left:340px !important;
                    top:40px;
                }
                div{
                    left:355px !important;
                    top:150px;
                }
            }
        }
        &.frente-2{
            position: absolute;
            top: 120px ;
            left: 0px;
            background-color: red;
        }
        &.frente-3{
            position: absolute;
            top: 380px !important;
            left: 0px;
            background-color: red;
            .modulo-redutor{
                rotate:180deg ;
                top: 30px;
            }
        }
        &.frente-4{
            position: absolute;
            top: 500px ;
            left: 0px;
            background-color: red;
            .modulo-redutor{
                rotate:180deg ;
                top: 30px;
            }
        }
    }
    .traseira{
        &-1{
            z-index: 2; 
            img{
                width: 33px;
                cursor: pointer;
                position: absolute;
                &:nth-child(1){
                    left:40px;
                    top:440px;
                    rotate: 180deg;
                }
                &:nth-child(2){
                    left:71px;
                    top:440px;
                    rotate: 180deg;
                }
                &:nth-child(3){
                    left:310px;
                    top:440px;
                }
                &:nth-child(4){
                    left:341px;
                    top:440px;
                }
            }
        }
        &-2{
            z-index: 2; 
            img{
                width: 33px;
                cursor: pointer;
                position: absolute;
                &:nth-child(1){
                    left:40px;
                    top:320px;
                    rotate: 180deg;
                }
                &:nth-child(2){
                    left:71px;
                    top:320px;
                    rotate: 180deg;
                }
                &:nth-child(3){
                    left:310px;
                    top:320px;
                }
                &:nth-child(4){
                    left:341px;
                    top:320px;
                }
            }
        }
    }
    .modulo-redutor{
        // border: 6px solid black;
        position: absolute;
        background-color: black;
        border-radius: 100% /80% 80% 150% 150%;
        width: 50px;
        height: 70px;
        &-1{
            left: 180px;
            top:50px;

        }
        &-2{
            left: 180px;
            top:50px;

        }
        &-2{
            left: 180px;
            top:310px;
            rotate: 180deg;

        }
        &-3{
            left: 180px;
            top: 435px;
            rotate: 180deg;

        }
    }

    .cabine{
        border: 1px solid grey;
        position: absolute;
        top: -40px;
        left: 10px;
        height: 200px;
        width: 400px;
        border-radius: 80% 80% 30% 30%;
        border-bottom: none;
        &::before{
            content: attr(data-truck);
            position: absolute;
            font-weight: 600;
            top: 50px;
            left: 175px
        }
    }
    .caminhao{
        border: 1px solid grey;
        position: absolute;
        top: 160px;
        left: 125px;
        height: 480px;
        width: 160px;
        border-radius: 0 0 5% 5%;
        border-top: none;
    }
}