.card-select-cargo{
    transition: 0.5s;
    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }
    p, h5, i{
        pointer-events: none;
    }
}

#leva__root{
    &> div{
        z-index: 10000;
    }
}

.cardSelected{
    width: 100% !important;
    height: 100% !important;
    border-radius: 0%;
    font-size: 5rem !important;
}
