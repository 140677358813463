@import '../../mixins.scss';

.error-form-color{
    &-green{
        color: $colorGreen !important;
    }
    &-red{
        transition: 0.5s;
        color: $colorRed !important;
    }
}