
@import '../../mixins.scss';
    .button-export{
        border-radius: 0 !important;
        border: 0.1px solid  #ccc9c9 !important;
        width: 40px;
        &-config{
            position: relative;
            display: flex !important;
            flex-direction: row ;
            align-items: center ;
            justify-content: center;
            .configs{
                position: absolute;
                z-index: 100;
                width: 150px;
                height: auto;
                right: 0;
                top: 35px;
                border: 0.1px solid rgb(130, 124, 124);
                background-color: #0C66EB;
                box-shadow: 0 0 16px #0000007a;
                display: flex;
                flex-direction: column;
                font-size: 0.9rem;
                text-align: start;
                div{
                    font-size: 0.8rem;
                    color: $colorWhite;
                    padding: 5px 7px;
                    transition: 0.25s;
                    &:hover{
                        background-color: #ccc9c9;
                        color: $colorBlack;
                        font-weight: 900
                    }

                }
            }
        }
    }