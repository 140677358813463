@import '../../mixins.scss';


.flex-grow-1{
    flex-grow: 1 !important;
}

.footer{
    // position: absolute;
    border-radius: 20px 20px 0 0;
    margin-top: 25px;
    background-color: $colorWhite;
    padding: 15px;
    text-align: center;
}