@import '../../mixins.scss';
.carregamento{
    width: 100%;
    height: 75vh;
    box-shadow: $boxShadow;
    position: relative;
}

.info-products{
    position: absolute;
    font-size: 1.2em;
    top: 10px !important;
    & pre{
        margin-left: 20px;
    }
}
.info-carga{
    right: 0;
    left: auto;
    background-color: white;
    margin: 8px !important;
    padding: 10px;
    box-shadow: 0px 0px 20px #00000054;
    height: 90% !important;
    overflow: auto;
    transition: 0.75s;
    border-radius: 10px;

    &.hide-card{
        height: 45px !important;
        overflow: hidden;
    }

    h4{
        font-size: 0.9rem;
        font-weight: bold;
    }
    h6{
        margin-top: 20px;
        font-size: 0.75rem;
        font-weight: bold;
    }
    li{
        font-size: 0.75rem;

    }
    & pre{
        margin-left: 0;
    }
    .rotateButton{
        transition: 0.5s;
        &.rotate-up{
            transform: rotate(-180deg);
        }
    }
}