@import '../../mixins.scss';

.login{
    height: 100vh ;
    margin: auto;
    min-width:250px;
    max-width:500px;
    display: flex;
    align-items: center;
    max-width: 600px;

    &-form{
        border-radius: 15px;
        background-color: $colorWhite;
        box-shadow: 1px 1px 10px #00000052;
        padding: 50px;
    }
    &-title{
        font-size: 18px;
        font-weight: 600;
    }
}

h1{
   font-size: 1.6rem;
   color: $colorAuxiliary1;
}
span{
    font-size: 1.2rem;
    color: $colorAuxiliary1;

}
hr{
    color: $colorAuxiliary1;

}

button{
    color: $colorAuxiliary1;
}

.recuperar-senha{
    padding-top: 5px;
    text-decoration: none;
    margin-top: 0 !important;
    line-height: normal !important;
    & a{
        text-decoration: none;
        color: $colorLink;
    }
    
}

.div-error-forgot-pass{
    display: flex;
    justify-content: space-between;
}


.brand-viwer-login{
    padding: 0;
    background-color: #008037;  
    position: relative;
    overflow: hidden;  
    img{
        width: 60%;
        margin: auto;
    }
    .street-view{
        position: absolute;
        left:0;
        top: 200px;
        width: 100%;
        height: 100px;
        background-color: #343434;
        transform: rotateX(45deg);
    }
    .street-view-lines{
        position: absolute;
        left:5px;
        top: 195px;
        width: 40px;
        height: 8px;
        background-color: #fff;
        background-repeat: repeat;
        z-index:0;
        animation: street-line s linear infinite;
        // transform: rotateX(45deg);
    }

    .wind{
        position: absolute;
        width: 50px;
        height: 6px;
        bottom: 100px;
        left: -50px;
        border-radius: 20%;
        background-color: rgb(192, 192, 215);
        border: 0.5px solid rgba(0, 0, 0, 0.263);
        z-index: -1;
        animation: wind 1s linear infinite;
        &1{
            animation: wind 1.5s linear infinite;

        }
        &4{
            animation: wind 1.75s linear infinite;

        }
        &5{
            animation: wind 2.4s linear infinite;

        }
        &6{
            animation: wind 1.2s linear infinite;

        }
    }
    .wind2{
        position: absolute;
        width: 50px;
        height: 5px;
        bottom: 100px;
        left: -50px;
        border-radius: 20%;
        background-color: rgb(192, 192, 215);
        border: 0.5px solid rgba(0, 0, 0, 0.263);
        z-index: -1;
        animation: wind 1.5s linear infinite;
    }
    .wind3{
        position: absolute;
        width: 50px;
        height: 5px;
        bottom: 100px;
        left: -50px;
        border-radius: 20%;
        background-color: rgb(192, 192, 215);
        border: 0.5px solid rgba(0, 0, 0, 0.263);
        z-index: -1;
        animation: wind 3s linear infinite;
    }

    .truck-image{
        position: absolute;
        background-image: url('../../assets/truck_img_2.png');
        background-color: #cccccc;
        background-repeat: no-repeat;
        height: 240px;
        width: 100%;
        top:70px;
        background-size: contain;
        background-color: transparent;
        animation: truck 4s 0.1s cubic-bezier(0.75, 0.82, 0.165, 1) infinite;
    }

    @keyframes truck {
        0% {transform: translate(-100vw)}
        45% {transform: translate(calc(17vw))}
        70% {transform: translate(calc(15vw))}
        // 75% {transform: translate(20vw)}
        100% {transform: translate(100vw)}
        
    }
    @keyframes wind {
        0% {
            opacity: 0;
            transform: translate(20px);
        }
        50% {
            opacity: 0.5;

        }
        100% {
            opacity: 0;
            transform: translate(-300px);
        }
        
    }
    @keyframes street-line {
        0% {
            transform: translate(20px);
        }
        50% {
        }
        100% {
            transform: translate(-300px);
        }
        
    }
}