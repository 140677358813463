@import '../../mixins.scss';

.card{
    &-user{
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid $borderColor !important;
        border-left: 1px solid $borderColor !important;
        border-right: 1px solid $borderColor !important;
        box-shadow: $boxShadowCard;
        padding: 20px;
    }
}



span{
    color: $colorBlack;
    white-space: nowrap;
    word-wrap: unset;
}

.form-control-input{
    &:focus{
        border-color: inherit;
        box-shadow: none !important;
    }
}
.row-filters{
    margin-top: 10px;
}

.filters-card{
    display: inline-block;
    
    .filters{
        position: relative;
        display: flex;
        width: -moz-fit-content;
        margin: 5px;
        min-width: 100px;
        max-width: fit-content;
        align-items: center;
        justify-content: space-between;
        background: bisque;
        padding: 5px 25px 5px 15px;
        border-radius: 14px;
        
        .title{
            font-size: 0.75rem;
            
        }

        i{
            transition: 0.5s;
            position: absolute;
            right: 6px;
            font-size: 0.9rem;
            cursor: pointer;
            &:hover{
                transform: translateX(12);
            }
        }
    }
        
}

.waypoints-novo{
    display: flex;
    position: relative;
    overflow: auto;
    height: 100px;
    align-items: center;

    .waypoints-novo-card{
        display: flex;
        justify-content: center;
        .filters-card   {
            .filters{
                &.filters-address{
                    color: $colorWhite;
                    .title{
                        color: $colorWhite;

                    }
                    background: $colorContent1 !important;

                }
            }
            &-duration{
                text-align: center;
                font-size: 0.8rem;
                position: absolute;
                top: 0;
                display: table;
                .filters{
                    width: fit-content;
                    display: flex;
                    align-items: initial;
                    display: flex;
                    flex-direction: row;
                    .title{
                        width: 150px;
                        font-size: 0.9rem;
                    }
                    .rotate-rigth{
                        transform: scaleX(-1);
                    }
                }
                
            }
            &-distance{
                text-align: center;
                font-size: 0.8rem;
                position: absolute;
                bottom: 0;                
                display: table;
                .filters{
                    width: fit-content;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: row;
                    .title{
                        width: 150px;
                        font-size: 0.9rem;
                    }
                    .rotate-rigth{
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
}




