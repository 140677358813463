@import '../../mixins.scss';
.div-row-editable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &-input {
        width: 50% !important;
    }
}
.modal-footer{
    &-no-border{
        border: none !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap !important;
        & button{
            max-width: 250px;
        }
    }
}

.animation-btn-ativar-logica{
    position: relative;
    overflow: hidden;
    &::before{
        content: '';
        font-weight: 500;
        transition: 0.5s;
        position: absolute;
        left:0;
        top: 0;
        background-color: $colorGreen;
        color: white;
        width:1%;
        white-space: nowrap;
        height:100%;
        opacity: 0;
    }
    & span{
        font-family: monospace;
        transition: 0.5s;
        position: relative;
        font-size: 1.2rem;
        font-weight: 400;
    }
    &.active-animation{
        border: none;
        & span{
            color: $colorWhite;
            font-weight: 600;
        }
        &:before{
            opacity: 100;
            width: 100%;
        }
    }

}
.active-animation-card{
    position: relative;
    overflow: hidden;
    &::before{
        text-align: center;
        content: attr(data-before);
        font-weight: 500;
        transition: .75s;
        position: absolute;
        right: calc(40% + 25px) !important;
        top: calc(10% - 18px) !important;
        padding: 5px;
        top: 8px;
        // rotate: -45deg;
        transform: rotate(-45deg);
        background-color: #28a745;
        color: white;
        width:1%;
        white-space: nowrap;
        height: fit-content;
        opacity: 0;
    }
    &.active-animation{
        // border: none;
        &:before{
            opacity: 100;
            width: 100%;
        }
    }
    &.active-animation-danger{
        // border: none;
        &:before{
            background-color: $colorRed;
            opacity: 100;
            width: 100%;
        }
    }
    &.active-animation-warning{
        // border: none;
        &:before{
            background-color: $colorYellow;
            opacity: 100;
            width: 100%;
        }
    }
    &.active-animation-success{
        // border: none;
        &:before{
            opacity: 100;
            width: 100%;
        }
    }
    &.active-animation-dark{
        // border: none;
        &:before{
            background-color: $colorBlack;
            opacity: 100;
            width: 100%;
        }
    }
    &-2{
       @extend .active-animation-card;
       &::before{
        position: absolute;
        // rotate: 45deg !important;
        transform: rotate(45deg) !important;
        left: calc(40% - 17px) !important;
        top: calc(10% - 20px) !important;
       } 
    }
    &-manifesto{
       @extend .active-animation-card;
       &::before{
        font-size: 0.7rem;
        position: absolute;
        // rotate: 45deg !important;
        transform: rotate(45deg) !important;
        left: calc(40% - 17px) !important;
        top: calc(10% - 1px) !important;
       } 
    }
}

.div-calculator{
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem !important;
    i{
        cursor: pointer;
    }
}


.card-manifesto{
    &.card{
        border-color: rgba(0, 0, 0, 0.39) !important;
    }
    height: 200px !important;
    width: 100% !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid !important;
    transition: 0.5s;
    i{
        transition: 0.5s;

    }
    cursor: pointer;
    &:hover{
        transform: translateY(-10px);
        color: rgb(0, 34, 255) !important;
        border-color: rgb(0, 34, 255) !important;
        box-shadow: 0px 10px 15px rgba(0, 34, 255, 0.448);
        i{
            color: rgb(0, 34, 255) !important;
        }
    }
}

.bi-transf{
    transition: 0.25s;
    cursor: pointer;
    
    &:hover{
        color: rgb(0, 34, 255);
    }
    &:active{
        color: rgb(2, 24, 161);
    }
}

.fotos-reports{
    position: relative;
    width: 65%;
    height: 25vh;
    margin-bottom: 25px;


    img{
        position: absolute;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.448);
        transition: 0.5s;
        width: 100%;
        &:hover{
            transform: translateY(-10px);
            z-index: 100;
        }
    }
}